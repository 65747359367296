
































import { copyToClipBoard } from '@/helpers/copyToClipBoard';

export default {
  name: 'WidgetViews',
  data: (): unknown => ({
    hint: false
  }),
  methods: {
    addToClipBoard(url: string): void {
      copyToClipBoard(url, null, true);
      this.hint = true;
      setTimeout(() => (this.hint = false), 1000);
    }
  }
};
